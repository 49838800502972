<template>
  <div v-if="mReservation.StatusId === 0">
    <message
      :message="'Check if the reservation can take place and accept or reject'"
    />
    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom has-text-centered"
    >
      <a
        @click="rejectReservation"
        class="button"
        :class="{ 'is-loading': isRejecting }"
        :disabled="isRejecting || isAccepting"
        >Reject</a
      >
      <a
        @click="acceptReservation"
        class="button is-success"
        :class="{ 'is-loading': isAccepting }"
        :disabled="isRejecting || isAccepting"
        >Accept</a
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import reservationProvider from '@/providers/reservation'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    message: Message,
  },

  props: {},

  data() {
    return {
      isAccepting: false,
      isRejecting: false,
      mReservation: null,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.mReservation = JSON.parse(JSON.stringify(this.reservation))
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    acceptReservation() {
      let self = this

      if (!self.isAccepting && !self.isRejecting) {
        self.isAccepting = true
        reservationProvider.methods
          .acceptReservation(self.reservation.Id)
          .then((response) => {
            if (response.status === 200) {
              self.mReservation.StatusId = response.data.StatusId
              self.setReservation(self.mReservation)
            }
          })
          .finally(() => {
            self.isAccepting = false
          })
      }
    },

    rejectReservation() {
      let self = this

      if (!self.isAccepting && !self.isRejecting) {
        self.isRejecting = true
        reservationProvider.methods
          .rejectReservation(self.reservation.Id)
          .then((response) => {
            if (response.status === 200) {
              this.mReservation.StatusId = response.data.StatusId
              this.setReservation(this.mReservation)
            }
          })
          .finally(() => {
            self.isRejecting = false
          })
      }
    },
  },
}
</script>

<style></style>
